@import './bootstrap.min.css';

.loader {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 100px;
}
.loading-wrap {
    text-align: center;
    margin-top: 24px;
	color:#ccc;
}
.posts-container {
	margin-left: 16px;
}
.nav-item {
	display: flex;
    align-items: center;
}
.logo {
	margin-right:48px;
}
.post-content img{
	width: 100%;
}
